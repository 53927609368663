import moment from "moment"
import * as React from "react"
import SecondaryLayout from "../components/common/secondary-layout"
import '../styles/pages/index/privacy.scss'


// markup
const PrivacyPage = () => {
	const date = moment().format("DD/MM/YYYY"); 
  return (
    <SecondaryLayout
      side={
        <div className="privacy-main-container--side">
          <h2 className="privacy-main-container--side__title">Privacy Policy</h2>
          <p className="privacy-main-container--side__description">
            Zenpli is committed to respect your privacy and data protection rights. This page provides information about Zenpli’s Privacy Policy. By reading through the policy, you will understand how and why personal data is collected by Zenpli.
          </p>
					<section className="privacy-main-container--side__list">
						<li><a href="#introduction">Introduction</a></li>
						<li><a href="#information-collected">Information Collected</a></li>
						<li><a href="#third-party-transfers">Third-Party Transfers</a></li>
						<li><a href="#usage">Usage of Information</a></li>
						<li><a href="#processing-data">Processing Data</a></li>
						<li><a href="#international-data-transfers">International Data Transfers</a></li>
						<li><a href="#data-retention">Data Retention</a></li>
						<li><a href="#revisions">Revisions</a></li>
					</section>


          <div className="privacy-main-container--side__version">
            {date} v.1.0
          </div>
        </div>
      }
      main={
        <div className="privacy-main-container--content">
          <h3 id="introduction">Introduction</h3>

          <p>Zenpli Inc. and our subsidiaries and affiliates (collectively, "Zenpli," "we", “us” or "our") enables real-time digital identity verification and fraud prevention products and services (collectively, the “Services”). When you (“User”) apply for or receive financial products and services from financial institutions in Latin America, these companies need to verify that you are a real person and that the information you provided is accurate. In certain cases, these financial institutions are our business customers (“Business Customer”) and as such, they use our Services.</p>

          <p>Your privacy is important to us. Our aim is to observe strict standards to protect the privacy of any information we may collect from Users across all electronics means for which our Business Customers and Users interact with the products and services Zenpli operates. </p>

          <p>This Privacy Policy describes the main aspects and procedures in use and the disclosure of the information provided by Users. This privacy policy sets out the type of information we may collect from Users, how and to whom we may disclose such information, the main purpose and use of data, and how we process data.</p>

          <p>The Services are often integrated with our Business Customers’ websites, consumer platforms and systems, which are governed by our customers’ privacy notices and terms of use.  Please review those notices carefully, as Zenpli does not control and cannot be responsible for these third parties’ privacy and information security practices.  This Privacy Policy is not a substitute for any privacy notice that our Business Customers are required to provide to their consumers or employees.</p>

          <h3 id="information-collected">Information Collected</h3>

          <p>The following includes a non-exhaustive list of certain personal information that Users and our Business Customers may provide to us through the Services:
            Name, last name, date of birth, ID number, and other personal identifiable information;
            Contact information such as email, phone number, address, and IP address;
            Financial information, including, but not limited to, credit history, transaction records, and others;</p>

					<li>(If applicable) a picture of National ID, Driver License, or Passport (if applicable);</li>
					<li>(If applicable) a picture/video of a User performing a “liveness check” (if applicable); </li>
					<li>(If applicable) a User’s facial biometric features; </li>
					<li>(If applicable) a User’s device type, operating system, unique device identifiers, device settings, geo-location data, and others;</li>
		

          <h3 id="third-party-transfers">Third-Party Transfers</h3>

          <p>Once Users provide information to Zenpli and agree to our Privacy Policy and Terms and Conditions through one of our Business Customer’s platforms, Users expressly agree that Zenpli can transfer data to other third parties with whom Zenpli has an existing contractual relationship. A non-exhaustive list of third-party receivers of User data is as follows: </p>

          <li>Entities within the Zenpli’s family of companies; and</li>
          <li>External data vendors or providers with whom Zenpli has a secure method of accessing and/or querying such services. </li>

          <p>In addition, Zenpli may share User’s personal data with third-parties for applicable legal reasons, where disclosing such information is deemed necessary:</p>

          <li>Comply with any valid legal process, governmental request, or applicable law, rule, or regulation;</li>
          <li>Investigate, remedy, or enforce potential Terms & Conditions violations by Business Customers or Users; and</li>
          <li>Detect and resolve any fraud or security concerns.</li>

          <h3 id="usage">Usage of Information</h3>

          <p>The main purpose and use of the data Zenpli collects is to:</p>

          <li>Verify the identity of Users to allow access to our Business Customers’ products and services;</li>
          <li>Develop, operate, improve, deliver, maintain, and protect our identity verification and fraud prevention products and services;</li>
          <li>Monitor and analyze trends and usage;</li>
          <li>Enhance the safety and security of our products and services;</li>
          <li>Enforce our Terms & Conditions and other usage policies.</li>

          <p>Zenpli will never sell the information collected to companies for the purpose of retargeting or marketing.</p>

          <h3 id="processing-data">Processing Data</h3>

          <p>All assembled data is processed maintaining its confidentiality by use of encryption methods and management processes that secure their protection. Zenpli implements all necessary procedures to prevent the alteration, loss and non-authorized processing or access of the personal data of users.</p>

          <p>For its part, the User declares that the data provided is exact, accurate and complete; and accepts full responsibility as the party who provides this information, holding Zenpli harmless from any liability related to this data.</p>

          <h3 id="international-data-transfers">International Data Transfers</h3>

          <p>Zenpli may transfer information collected through the Services outside of the country from which it originated, including to the United States or where our affiliates and external  data vendors or providers maintain facilities. Such transfers are required in order to provide the Services.</p>

          <h3 id="data-retencion">Data Retention</h3>

          <p>In general, Zenpli retains personal information for as long as reasonably necessary to: </p>

          <li>Provide our Services as directed by our Business Customers or for other compatible purposes, which include (where permitted by applicable law), enhancing and improving these Services, quality control and related purposes intended to make sure that these Services are functioning properly, and supporting the recordkeeping and related obligations of our Business Customers;</li>
          <li>Comply with legal obligations; and </li>
          <li>Resolve disputes and enforce the terms of our Business Customer agreements (as permitted by applicable law).</li>

          <h3 id="revisions">Revisions</h3>

          <p>Zenpli may revise or modify this Privacy Policy in response to changing developments. Any modification to this Privacy Policy shall be reflected by a changing the date at the top of the Privacy Policy published on our website. The most recent version will govern the use of User’s personal data. If you have any questions about this policy or your privacy rights, you can contact us at privacy@zenpli.com.</p>
        </div>
      }
    />
  )
}

export const Head = () => (
  <>
    <title>Zenpli » Privacy</title>
    <meta name="description" content="Zenpli » Privacy" />
  </>
)

export default PrivacyPage
