import React, {useState, useEffect} from "react"
import Header from "../header"
import Footer from "../footer"
import './index.scss'

export interface Ilayout {
  side: JSX.Element
  main: JSX.Element
}

const SecondaryLayout = ({ side, main }: Ilayout) => {

  const [scrollTop, setScrollTop] = useState(0);

  /* useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop);    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]); */

  return (
    <React.Fragment >
      <Header extraHeaderClass={scrollTop > 1 ? 'sticky-header': ''}/>
      <div className={`zenpli-secondary-layout ${scrollTop > 1 ? 'sticky-header': ''}`}>

        <div className="zenpli-secondary-layout__side">
          {side}
        </div>
        <div className="zenpli-secondary-layout__main">
          {main}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default SecondaryLayout